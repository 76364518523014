/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//Calendar styles
@import "./theme/calendar.scss";

ion-toast{
  --background: var(--ion-color-dark);
}

ion-content.action{
  --ion-text-color: #007dae;
  --background: transparent!important;
}

.bg_container {
  background: var(--ion-color-gray-100);
}

ion-app.cameraView, ion-app.cameraView ion-content, ion-app.cameraView .nav-decor, ion-app.cameraView ion-card,
ion-app.cameraView.bg_container ion-app.cameraView ion-content:not(ion-card), ion-app.cameraView form,
ion-app.cameraView ion-card.sc-ion-card-md-h
{
  background: transparent none !important;
  background: -webkit-gradient(linear, left bottom, right top, color-stop(-80%, transparent), color-stop(53%, transparent)) !important;
  background: -webkit-linear-gradient(45deg, transparent -80%, transparent 53%) !important;
  background: linear-gradient(45deg, transparent -80%, transparent 53%) !important;
  --ion-background-color: linear-gradient(45deg, transparent -80%, transparent 53%) !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

ion-app.cameraView form, ion-app.cameraView ion-card ion-label{
  visibility: collapse !important;
}

.t-white {
  color: white;
}

.t-disabled {
  color: #787878;
}

.ionic-container {
  max-width: 411px;
  max-height: 730px;
  margin: auto;
}
:root{
  ion-title{
    h1{
      font-size: 20px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 12px;
    }
  }
}
